.text{
    padding-bottom: 25px;
    max-width: 300px;
    width: 100%;
    margin: auto;
}

html, body, #root {
    height: 100%;
  }

input{
    height: 28px;
    width: 100%;
    background-color: #ffe6e6;
    border-width: thin;
    max-width: 294px
}

.loginButton{
    background-color: black;
    height: 30px;
    width: 85px;
    color: white;
    font-family: sans-serif;
}

.logintext{
    padding-left: 120px;
    font-size: 22px;
    font-family:  sans-serif;
}

h2{
    font-size: 24px;
    line-height: 30px;
    font-family: sans-serif;
}

.navBar{
    background-color: #d6d6d6;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);
    padding: 10px 10px;
  }

  .containerr h2{
    margin: 0;
}

.error{
    font-family: sans-serif;
    color:red;
    font-size: 12px;
}

.container{
    max-width: 500px;
    margin: auto;
    padding-top: 10%;
}

.fields{
    padding-bottom: 25px;

}

.logbutton{
    text-align: right;
}