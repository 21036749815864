
.containerr{
    max-width: 1400px;
    width: 100%;
    display: flex;
  }

.footerButton{
    display: flex;
    margin-left: 20px;
    justify-content: end;
    margin-right:20px;
}

.logoutButton{
    background-color: black;
    height: 30px;
    width: 85px;
    color: white;
    font-family: sans-serif;
}

.run{
    background-color: black;
    height: 30px;
    width: 85px;
    color: white;
    font-family: sans-serif;
}
.stop{
    background-color: black;
    height: 30px;
    width: 85px;
    color: white;
    font-family: sans-serif;
}

.example-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

.navBar{
    background-color: #d6d6d6;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);
    padding: 10px 10px;
  }

 h2{
    font-size: 24px;
    line-height: 30px;
    font-family: sans-serif;
}

.containerr h2{
    margin: 0;
}

body{
    margin: 0;
}

.containerr span{
    font-weight: 500;
    font-size: larger;
}

.divContainer{
    margin-left: auto;
    text-align: end;
}

button:disabled,
    button[disabled] {
        background-color: grey;
        height: 30px;
        width: 85px;
        color: white;
        font-family: sans-serif;
        cursor: not-allowed;
    }

.button {
        border: 1px solid black;
        background-color: red;
        color: white;
        padding: 15px;
}

@media only screen and (max-width: 600px) {
    .ag-theme-alpine {
        margin: 0px !important;
        width: 100% !important;
    }
  }

  .navBar2{
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #d6d6d6;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);
    padding: 10px 10px;
    z-index: 10;
  }

  .overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 1;
    cursor: not-allowed;
  }
  .disabled {
    opacity: 0.5; /* Reduce opacity to indicate disabled state */
    pointer-events: none; /* Disable pointer events to prevent user interaction */
    cursor: not-allowed;
    background-color: transparent;
    z-index: 1;
  }

  .customCheckbox{
    width: 100%;
    max-width: 17px;
    display: flex;
    align-items: center;
  }

  .customCheckbox input{
flex-shrink: 0;
width: 100%;
max-width: 20px;
  }

  .customCheckbox .form-check-label{
    margin-left: 5px;
  }
  .status {
    display: flex;
    flex-direction: row;
    font-family: sans-serif;
    justify-content: space-between;
    margin: 0 25px;
  }
  
  @media screen and (max-width: 768px) {
   .containerr h2{
    font-size: 16px;
   }
   .status{
    font-size: 14px;
   }
  }
  


  